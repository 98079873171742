import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;


// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.tabs';

import Accordions from './modules/Accordions';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.nav-main').toggleClass('menu-active');
		$('.nav-bg').toggleClass('is-active');
		e.preventDefault();
	});

	// show more
	$('.show-more').on('click', function() {
		$(this).siblings('.additional-content').toggle();
		$(this).toggleClass('is-active');
		var text = $(this).find('.expand-text').text();
		if(text == "Expand"){
			$(this).find('.expand-text').text('Hide');
		}else {
			$(this).find('.expand-text').text('Expand');
		}
	});

	// video modal open
	$('.modal-link').on('click', function () {
		$('#video-modal').foundation('open');
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
		$('.video-transcript .btn').attr("href", $(this).attr('data-video-transcript'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .video-transcript .btn-primary').on('click', function (e) {
		var tempLink = $('.reveal-video iframe').attr('src');
		var tempTitle = $('.reveal-video iframe').attr('title');
		$('.reveal-video iframe').attr('src', '');
		setTimeout(function(){ 
			$('.reveal-video iframe').attr('src', tempLink);
			$('.reveal-video iframe').attr('title', tempTitle);
		}, 500);
	});
	$(document).on('keyup', function (evt) {
		if (evt.keyCode == 27) {
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// role modal open
	$('.role-modal-link').on('click', function () {
		$('#role-modal').foundation('open');
		//$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.role-modal-content').html($(this).attr('data-role-content'));
		$('.role-modal-title').text($(this).attr('data-role-title'));
	});

	// policy modal open
	$('.policy-modal-link').on('click', function () {
		$('#policy-modal').foundation('open');
		$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.policy-modal-title').text($(this).attr('data-apply-title'));
	});

	// Close policy modal on apply
	$('.btn-accept').on('click', function () {
		$('#policy-modal').foundation('close');
	});

	$('.tabs-title a').on('click', function() {		
		var tabId = $(this).attr('data-tabs-target');
		$('.tab-accordion-title').removeClass('is-active');
		$(`.tab-accordion-title[data-tab-id='${tabId}']`).addClass('is-active');
	});		

	// accordions
	const accordion = new Accordions();
	accordion.init();

});
